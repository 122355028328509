

import i18n from 'i18next';
import Backend from 'i18next-xhr-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import en from './en';
import fr from './fr';

i18n
    .use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        fallbackLng: 'fr',
        ns: ['translation'],
        defaultNS: 'translation',
        debug: process.env.NODE_ENV !== 'production',
        load: 'languageOnly',
        interpolation: {escapeValue: false},

        react: {
            useSuspense: false,
            wait: true
        }
    });

for (const ns in fr) {
    if (fr.hasOwnProperty(ns)) {
        i18n.addResourceBundle('fr', ns, fr[ns], true, true);
    }
}

for (const ns in en) {
    if (en.hasOwnProperty(ns)) {
        i18n.addResourceBundle('en', ns, en[ns], true, true);
    }
}

export default i18n;

