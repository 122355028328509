/**
 * Implement Gatsby's SSR (Server Side Rendering) APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/ssr-apis/
 */

// eslint-disable-next-line no-unused-vars
import React from 'react';
import i18next from 'i18next';

import i18n from './src/locales/i18n';
import { I18nextProvider } from 'react-i18next';
import { PageContext } from './pageContext';

/**
 * Wrap all pages with a Translation provider and set the language on SSR time
 */
export const wrapRootElement = ({ element }) => {
    return <I18nextProvider i18n={i18n}>{element}</I18nextProvider>;
};

/**
 * Wrap all pages with a Translation provider and set the language on SSR time
 */
export const wrapPageElement = ({
    element, props
}) => {
    if (i18next.language !== props.pageContext.lang) {
        i18next.changeLanguage(props.pageContext.lang);
    }

    return <PageContext.Provider value={props.pageContext}>{element}</PageContext.Provider>;
};
